<template>
	<div class="_w-max _pc-pad-bottom-75">
		<div class="_w-max display-none" style="overflow-x: hidden;overflow-y: hidden;object-fit: fill;" v-show="showSwiper">
			<img style="width: 100%;" :src="agent_info.logistics_banner.url"/>
		</div>
		<img class="_pc-display-none" style="width: 100%;object-fit: cover;" src="~@/assets/image/hangda/mb_banner12.png" alt="">
		<div class="_pc-pad-x-280 _flex-row-center-mid _pc-pad-bottom-70 mb-pad-x-15">
			<div class="search-box mb-border-radius-10 mb-h-60 mb-pad-x-15 banner-mar-top">
				<div class="search-box-left">
					<img class="_pc-w-30 _pc-mar-right-25 mb-mar-right-15 mb-w-18" src="~@/assets/image/hangda/sousuo.png" alt="">
					<input type="text" class="_pc-font-24 mb-flex-1 mb-font-15" v-model.trim="expresssn" :placeholder="$t('请输入查询单号...')" @keyup.enter="search">
				</div>
				<div class="search-box-right _pc-pad-x-30 display-none" @click="search()">{{ $t('search') }}</div>
				<div class="mb-search-btn _pc-display-none mb-font-15" @click="search()">{{ $t('search') }}</div>
			</div>
		</div>
		<div v-for="(item,index) in list" :key="index">
			<div class="_pc-pad-x-280 _w-max _pc-mar-bottom-20 display-none">
				<div class="_w-max _pos-rel">
					<div class="_w-max search-content">
						<div class="li-item _flex-row" v-for="(items,indexs) in item.report_log" :key="indexs">
							<div class="_pc-font-20 _pc-w-180 _flex-col item-left">
								<div :style="items.status?'color: #4284DC;':''">{{ items.title }}</div>
								<div class="status" v-if="items.status">{{ $t('当前状态') }}</div>
							</div>
							<div class="item-block-box _flex-row-center _pc-w-120" style="flex-shrink: 0;">
								<div class="item-block" :style="items.status?'width:27px;height:27px;background-color:#4284DC;margin-top:2px;':'width:27px;height:27px;background-color:#4284DC;margin-top:2px;'"></div>
							</div>
							<div class="_pc-font-20 _pc-mar-right-35" style="color: #333;flex-shrink: 0;line-height: 1.7;">{{ items.time }}</div>
							<div class="_pc-font-20 _flex-wrap" style="color: #333;line-height: 1.7;">{{ items.content }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="_pc-display-none mb-pad-x-15 mb-pad-y-20">
				<div class="progress-container">
					<div class="progress-item" v-for="(items,indexs) in item.report_log" :key="indexs">
						<div class="progress-line"></div>
						<div class="header" :class="indexs == item.report_log.length-1 ? 'now-color': ''">
							<div class="now-label" v-if="indexs == item.report_log.length-1">{{ $t('当前') }}</div>
							<strong>{{ items.title }}</strong>
							<div class="time">{{ items.time }}</div>
						</div>
						<div class="content">{{ items.content }}</div>
					</div>
				</div>
			</div>
			<div v-if="!item.is_main">
				<div class="_pc-pad-x-280 _w-max" v-if="item.show">
					<!-- 俄罗斯  -->
					<div class="-w-max _pos-rel" v-if="item.area_id == 120">
						<img v-if="item.date.index == 1" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/1.jpg">
						<img v-if="item.date.index == 2" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/2.jpg">
						<img v-if="item.date.index == 3" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/3.jpg">
						<img v-if="item.date.index == 4" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/4.jpg">
						<img v-if="item.date.index == 5" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/5.jpg">
						<img v-if="item.date.index == 6" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/6.jpg">
						<img v-if="item.date.index == 7" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/7.jpg">
						<img v-if="item.date.index == 8" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/8.jpg">
						<img v-if="item.date.index == 9" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/9.jpg">
						<img v-if="item.date.index == 10" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/10.jpg">
						<img v-if="item.date.index == 11" class="_w-max" src="~@/assets/image/hangda/map/type-new-1/11.jpg">
						<div class="dropBox0">
							<div class="_pos-abs _z-index-1 _b drop" v-for="(itemes,indexes) in item.date.time" :key="indexes">
								<div>NO.{{indexes+1}}</div>
								<div>{{itemes}}</div>
							</div>
						</div>
					</div>
					<!-- 哈萨克斯坦 -->
					<div class="-w-max _pos-rel" v-else-if="item.area_id == 119"> 
						<img v-if="item.date.index == 1" class="_w-max" src="~@/assets/image/hangda/map/type-new-0/1.jpg">
						<img v-if="item.date.index == 2" class="_w-max" src="~@/assets/image/hangda/map/type-new-0/2.jpg">
						<img v-if="item.date.index == 3" class="_w-max" src="~@/assets/image/hangda/map/type-new-0/3.jpg">
						<img v-if="item.date.index == 4" class="_w-max" src="~@/assets/image/hangda/map/type-new-0/4.jpg">
						<img v-if="item.date.index == 5" class="_w-max" src="~@/assets/image/hangda/map/type-new-0/5.jpg">
						<img v-if="item.date.index == 6" class="_w-max" src="~@/assets/image/hangda/map/type-new-0/6.jpg">
						<div class="dropBox">
							<div class="_pos-abs _z-index-1 _b drop" v-for="(itemes,indexes) in item.date.time" :key="indexes">
								<div>NO.{{indexes+1}}</div>
								<div>{{itemes}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="_pc-pad-x-280 _w-max " v-else>
					<div class="-w-max _pos-rel">
						<img class="_w-max" src="~@/assets/image/hangda/map/type-new-0/1.jpg">
					</div>
				</div>
			</div>
			<div v-else>
				<div class="_pc-pad-x-280 _w-max ">
					<div class="-w-max _pos-rel">
						<img class="_w-max" :src="item.track_map">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { logisticsSearch, bannerList } from '@/api/jiyun';
	import Swiper from '@/assets/js/swiper.min.js'
	export default {
		name: 'index-search',
		data() {
			return {
				// metaInfo
				title: this.$t('page_title.search'),
				keyWords:'航达',
				description:``,
				showSwiper: true,
				list:[],
				expresssn: '',
				agent_info: {},
				lang: this.$i18n.locale,
				map:{
					show:false,
					type:0,//终点 1->哈萨克斯坦 0->俄罗斯
					rate:{},//进度
				},
			}
		},
		metaInfo() {
			return {
				title: this.title, // set a title
				meta: [
					{
						// set meta
						name: "keyWords",
						content: this.keyWords,
					},
					{
						name: "description",
						content: this.description,
					},
				],
			};
		},
		mounted() {
			this.expresssn = this.$route.query.expresssn;
			if(this.expresssn){
				this.search();
			}
			this.bannerList();
		},
		updated(){
			this.nav_swiper();
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			nav_swiper(){
				var mySwiper = new Swiper('.swiper',{
					direction: 'horizontal',
					loop:true,
					autoplay : {
						delay:3500,
						disableOnInteraction: false,
					},
					// 如果需要前进后退按钮
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				})
			},
			// 搜索
			search() {
				this.list = [];
				if(!this.expresssn) return this.$Message.error(this.$t('请输入查询单号'));
				let param = {
					expresssn: this.expresssn,
					domain_url: window.location.origin
				}
				logisticsSearch(param).then((res)=>{
					this.list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
		}
	}
</script>

<style scoped>
	input:not(:first-child) {
		flex: 1;
		height: 100%;
		outline: none;
	}

	input:not(:first-child)::placeholder {
		color: #999 !important;
		/* font-size: 24px; */
	}

	/* input::first-child::placeholder {
		color: #999 !important;
		font-size: 24px;
	} */
	.index {
		position: relative;
		padding-bottom: 125px;
	}

	.search-box {
		width: 100%;
		height: 110px;
		border-radius: 28px;
		background-color: #ffffff;
		box-shadow: 0px 8px 40px 0px rgba(69, 69, 69, 0.08);
		margin-top: -55px;
		padding: 21px 21px 21px 31px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.search-box .search-box-left {
		display: flex;
		align-items: center;
		height: 100%;
		flex: 1;
	}

	.search-box .search-box-right {
		min-width: 147px;
		height: 68px;
		background: #4284DC;
		border-radius: 12px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		cursor: pointer;
	}
	.search-content {
		/* border-left: 2px dashed #bebebe; */
		min-height: 100px;
	}
	.search-content .li-item {
		padding-bottom: 60px;
		position: relative;
	}
	.search-content .li-item .item-left {
		color: #333;flex-shrink: 0;line-height: 1.7;align-items: flex-end;
	}
	.status {
		margin-top: 6px;
		border-radius: 6px;
		background-color: #4284DC;
		color: #ffffff;
		padding: 2px 8px;
		font-size: 16px;
	}
	.search-content .li-item .item-block-box::after {
		content: "";
		position: absolute;
		width: 5px;
		height: 100%;
		border-left: 3px dashed #bebebe;
		margin-left: 6px;
		margin-top: 5px;
	}
	.search-content .li-item:last-child .item-block-box::after {
		content: "";
		position: absolute;
		width: 5px;
		height: 100%;
		border-left: none;
		margin-left: 6px;
	}
	.search-content .li-item .item-block-box .item-block {
		width: 18px;height: 18px;border-radius: 3px;margin-top: 8px;background-color: #bebebe;transform: rotate(45deg);
		position: relative;
		z-index: 3;
	}

	.drop{
		font-size: 1vw;
	}

	.dropBox .drop:nth-child(1){
		bottom:10%;
		right:8%;
	}
	.dropBox .drop:nth-child(2){
		bottom:20%;
		right:28%;
		text-align: right;
	}
	.dropBox .drop:nth-child(3){
		bottom:34%;
		right:16%
	}
	.dropBox .drop:nth-child(4){
		bottom:49%;
		right:26%;
	}
	.dropBox .drop:nth-child(5){
		top:34%;
		right:34%;
	}
	.dropBox .drop:nth-child(6){
		top:46%;
		left:33%;
		text-align: right;
	}





	.dropBox0 .drop:nth-child(1){
		bottom:8%;
		right:8%;
	}
	.dropBox0 .drop:nth-child(2){
		bottom:24%;
		right:12%
	}
	.dropBox0 .drop:nth-child(3){
		bottom:29%;
		right:32%;
		text-align: right;
	}
	.dropBox0 .drop:nth-child(4){
		bottom:47%;
		right:27.4%;
	}
	.dropBox0 .drop:nth-child(5){
		top:36%;
		right:33.2%;
	}
	.dropBox0 .drop:nth-child(6){
		top:51%;
		left:35%;
		text-align: right;
	}
	.dropBox0 .drop:nth-child(7){
		top: 42%;
		left: 29%;
		text-align: right;
	}
	.dropBox0 .drop:nth-child(8){
		top: 28%;
		left: 42%;
	}
	.dropBox0 .drop:nth-child(9){
		top: 18%;
		left: 32%;
	}
	.dropBox0 .drop:nth-child(10){
		top: 25%;
		left: 12%;
		text-align: right;
	}
	.dropBox0 .drop:nth-child(11){
		top: 18%;
		left: 2%;
		text-align: right;
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.drop{
			font-size: 1.36vw;
		}
		* {
			padding: 0;
			margin: 0;
		}
		*,
		*::before,
		*::after {
		  	box-sizing: border-box;
		}
		ul {
			padding: 0 !important;
		}
		ul>li{
			list-style: none;
		}
		.banner-mar-top{
			margin-top: -35px;
		}
		.display-none {
			display: none !important;
		}
		/* pad */
		.mb-pad-x-15 {
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-20 {
			padding-top: 1.25rem;
			padding-bottom: 1.25rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex- {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-search-btn {
			padding: 0.5rem 0.9375rem;
			background: #4284DC;
			border-radius: 8px;
			color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
		.progress-container {
			width: 100%;
		}
		.progress-container .progress-item {
			width: 100%;
			position: relative;
			/* border: 1px solid #c18881; */
			padding-bottom: 1.275rem;
			padding-left: 1.875rem;
		}
		.progress-container .progress-item .progress-line {
			position: absolute;
			width: 1.875rem;
			left: 0;
			height: 100%;
			/* background-color: #4284DC; */
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.progress-container .progress-item:last-child .progress-line::before {
			content: '';
			position: absolute;
			top: 0.125rem;
			width: 1.125rem;
			aspect-ratio: 1/1;
			border-radius: 0.1875rem;
			z-index: 1;
			transform: rotate(45deg);
			background: #4284DC;
		}
		.progress-container .progress-item .progress-line::before {
			content: '';
			position: absolute;
			top: 0.125rem;
			width: 0.9375rem;
			aspect-ratio: 1/1;
			border-radius: 0.1875rem;
			z-index: 1;
			transform: rotate(45deg);
			background: #999;
		}
		.progress-container .progress-item .progress-line::after{
			content: '';
			position: absolute;
			width: 1px;
			height: 100%;
			border-right: 1px dashed #bebebe;
		}
		.progress-container .progress-item:last-child .progress-line::after {
			display: none;
		}
		.now-color {
			color: #4284DC;
		}
		.progress-container .progress-item .header {
			display: flex;
			align-items: flex-end;
			column-gap: 0.375rem;
		}
		.progress-container .progress-item .content {
			/* font-size: 0.9375rem; */
			margin-top: 0.5rem;
		}
		.progress-container .progress-item .header .now-label {
			font-size: 0.75rem;
			color: #fff;
			background: #4284DC;
			border-radius: 0.20rem;
			padding: 0.125rem 0.25rem;
			margin-right: 0.375rem;
		}
		.progress-container .progress-item .header .time {
			font-size: 0.75rem;
		}
	}
</style>